import React, { FC, Fragment } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import { Container } from 'layout';
import Button from 'common/Button';
import GatsbyImage from 'common/GatsbyImage';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import useScreenRecognition from 'hooks/useScreenRecognition';
import { ICampaignBannerProps } from './model';
import './CampaignBanner.scss';

const CampaignBanner: FC<ICampaignBannerProps> = ({
  background,
  mobileBackground,
  foregroundImage,
  mobileForegroundImage,
  disclaimer = '',
  description = '',
  cta = [],
  buttonsClassName = '',
  bannerTheme,
  logo,
}) => {
  const desktopBannerBackground = background?.[0]?.properties;
  const mobileBannerBackground = mobileBackground?.[0]?.properties;
  const desktopImageBanner = foregroundImage?.[0]?.properties;
  const mobileImageBanner = mobileForegroundImage?.[0]?.properties;

  const { isDesktop, isNotMobile } = useScreenRecognition();

  const bannerImage = isNotMobile ? desktopImageBanner : mobileImageBanner;
  const bannerBackground = isNotMobile ? desktopBannerBackground : mobileBannerBackground;
  const isEmptyDisclaimerBlock = !!bannerImage && !disclaimer;

  const DisclaimerElement = () =>
    disclaimer || isEmptyDisclaimerBlock ? (
      <DangerouslySetInnerHtml
        className={classNames('campaign-banner__disclaimer', {
          'campaign-banner__disclaimer--empty': isEmptyDisclaimerBlock,
        })}
        html={disclaimer || ' '}
      />
    ) : null;

  return (
    <div
      className={classNames('campaign-banner', {
        [`${bannerTheme}`]: bannerTheme,
      })}
    >
      <div className="campaign-banner__background">
        {bannerBackground?.image && (
          <GatsbyImage
            image={bannerBackground.image}
            alt={bannerBackground?.imageAlt}
            style={{ position: 'absolute' }}
            className="campaign-banner__background-img"
            isLazyLoading={false}
            fadeIn={false}
          />
        )}

        <Container fluid>
          <div className="campaign-banner__grid">
            <div className="campaign-banner__info  campaign-banner__info--dark-theme">
              {logo?.length ? (
                <div className="campaign-banner__logo">
                  <GatsbyImage
                    image={logo[0].properties.image}
                    alt={logo[0].properties.imageAlt}
                    objectFit="contain"
                    className="home-banner__logo-image"
                    isLazyLoading={false}
                    objectPosition="0 50%"
                  />
                </div>
              ) : null}
              {description ? (
                <DangerouslySetInnerHtml
                  className="campaign-banner__description"
                  html={description}
                />
              ) : null}
              {cta?.length > 0 && (
                <div
                  className={classNames('campaign-banner__buttons', {
                    [`${buttonsClassName}`]: buttonsClassName,
                  })}
                >
                  {cta.map(({ properties: { link, ariaLabel = '' } }, idx) => {
                    const linkItem = link?.[0];

                    return (
                      <Fragment key={linkItem?.name}>
                        <Button
                          to={linkItem?.url}
                          ariaLabel={ariaLabel}
                          variant={idx === 0 ? 'primary-link' : 'secondary-link'}
                          iconSuffix="chevron-right"
                          target={linkItem?.target}
                        >
                          {linkItem?.name}
                        </Button>
                        {` `}
                      </Fragment>
                    );
                  })}
                </div>
              )}
            </div>
            {bannerImage?.image ? (
              <div className="campaign-banner__image">
                <GatsbyImage
                  image={bannerImage.image}
                  alt={bannerImage.imageAlt}
                  objectFit="contain"
                  className="campaign-banner__image-img"
                  isLazyLoading={false}
                  fadeIn={false}
                />
              </div>
            ) : null}
          </div>
          {isDesktop ? <DisclaimerElement /> : null}
        </Container>
        {isDesktop ? null : <DisclaimerElement />}
      </div>
    </div>
  );
};

export const query = graphql`
  fragment FragmentCampaignBanner on TCampaignBanner {
    properties {
      header
      background {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      mobileBackground {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 700) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      description
      title
      titleSize
      titleSizeMobile
      foregroundImage {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 490, webpQuality: 70) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      mobileForegroundImage {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 300, webpQuality: 70) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      image {
        ...FragmentGatsbyProps
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      topImage {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 254, webpQuality: 50) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      titleImage {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 420, webpQuality: 50) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      titleText
      disclaimer
      cta {
        properties {
          ariaLabel
          link {
            name
            url
            target
          }
        }
      }
      logo {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 272, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      btnAriaLabel
      isSlimVersion
      hiddenDescription
      isBackgroundTransparent
      bannerTheme
    }
    structure
  }
`;

export default CampaignBanner;
